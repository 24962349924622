import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Layout from "../../components/Layout"
import HeroHeader from "../../components/HeroHeader"
import HeroImage from "../../images/automotive.jpeg"
import SEO from "../../components/SEO"
import ApplicationQuote from "../../components/ApplicationQuotes"
import AutoImages from "../../components/applicationImageComponents/automotiveImages"

const PageBody = styled.main`
  max-width: 1000px;
  margin: 0 auto;

  h1 {
    font-size: 2.5em;
  }
`

const Modal = styled.div`
  position: fixed;
  width: 900px;
  height: 600px;
  bottom: 50%;
  margin-bottom: -300px;
  left: 50%;
  margin-left: -450px;
  background-color: green;
  animation: fadeIn ease 0.5s;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 910px) {
    width: 750px;
    height: 500px;
    margin-bottom: -250px;
    margin-left: -375px;
  }

  @media (max-width: 775px) {
    width: 600px;
    height: 400px;
    margin-bottom: -200px;
    margin-left: -300px;
  }

  @media (max-width: 625px) {
    width: 450px;
    height: 300px;
    margin-bottom: -150px;
    margin-left: -225px;
  }

  @media (max-width: 475px) {
    width: 350px;
    height: 233px;
    margin-bottom: -116.5px;
    margin-left: -175px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  i {
    position: absolute;
    top: 20px;
    right: 20px;
    /* z-index: 2; */
    color: red;
    font-size: 2em;
    cursor: pointer;
  }

  button {
    background-color: transparent;
    padding: 0.5rem;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
      background-color: red;
      color: white;
    }
  }

  #machine-btn {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }

  #application-btn {
    position: absolute;
    bottom: 30px;
    left: 145px;
  }
`

const StyledImageGalleryHeader = styled.h2`
  max-width: 1400px;
  margin: 1rem auto;
  border-bottom: 2px solid red;
`

const StyledImageGallery = styled.div`
  max-width: 1400px;
  display: flex;
  flex-flow: row wrap;
  margin: 1rem auto;
  justify-content: space-between;
`

const Thumbnail = styled.div`
  position: relative;
  max-width: 330px;
  max-height: 330px;
  overflow: hidden;
  cursor: pointer;
  margin: 10px 0;

  i {
    position: absolute;
    top: 10px;
    right: 20px;
    color: white;
    font-size: 1.4em;
    /* z-index: 3; */
    pointer-events: none;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

    &:hover {
      color: red;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    display: none;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    bottom: 0;
    color: white;
    animation: slideIn ease 0.5s;
    pointer-events: none;

    @keyframes slideIn {
      0% {
        margin-bottom: -150px;
      }
      100% {
        margin-bottom: 0;
      }
    }

    h4 {
      margin-left: 15px;
    }

    a {
      text-decoration: none;
      color: white;
      pointer-events: auto;
      &:hover {
        color: red;
      }
    }
  }

  &:hover {
    .overlay {
      display: block;
    }
  }
`

const BackButton = styled.button`
  margin: 2rem;
  font-size: 1em;
  border: none;
  padding: 1rem;
  background-color: red;
  color: white;
  cursor: pointer;
`

const AutomotivePage = () => {
  const [modal, setModal] = useState({
    display: "none",
    name: "",
  })
  const [machineImage, setMachineImage] = useState({})
  const [machineLink, setMachineLink] = useState({})

  const [model, setModel] = useState({
    name: "",
  })

  function handleImageClick(e) {
    setModal({
      display: "block",
      name: e.target.dataset.model,
    })
    setMachineImage(e.target.src)
    setMachineLink(e.target.dataset.link)
    setModel({ name: e.target.dataset.model })
  }

  return (
    <>
      <SEO title="Truck and Automotive" description="" />

      <Layout>
        <HeroHeader
          heroImage={HeroImage}
          // title="Warehouse Application"
          // subHeading="Keep Your Unique Warehouse Clean with Our Specialized Products."
          buttonTitle="See More"
          showButton="none"
        />
        <PageBody>
          <h1>Truck and Automotive</h1>
          <p>
            Truck/Automotive companies have increasingly high standards for
            housecleaning. Many automobile dealerships have discovered the labor
            savings available with our battery scrubber's cylindrical scrub deck
            feature. By sweeping and scrubbing in a single path, their operators
            can clean around service bays without clogged squeegee hoses or
            streaked floors. When in the industrial truck repair bays it is
            important the scrubbers be able to survive the harsh application.
          </p>
          <h2>Factory Cat Machines Help With Your Daily Needs:</h2>
          <ul>
            <li>
              The fully accessible recovery tank and drain saver basket simplify
              daily clean-up of the scrubber.
            </li>
            <li>
              Our disk battery scrubbers are typically found in the car
              dealerships showrooms, where deluxe floors are increasingly
              common.
            </li>
            <li>
              Often called on to scrub up spills of hydraulic fluid, oils or
              coolant, the scrubbers need to be built to perform on a moments'
              notice.
            </li>
          </ul>
          <ApplicationQuote quote="Automotive facilities may have a shop in the back with nuts, bolts, and fluids on the floor while the showroom is a clean tile or concrete finish.  FactoryCat offers easy brush change-out so that you can accomplish both tasks often with one machine. -JC" />
          <ApplicationQuote quote="Powerful scrubbing on a small, maneuverable footprint for cleaning greasy floors in a shop full of vehicles and tools.  Recovery tanks can be cleaned easily, no matter how long technicians have left dirty water sitting in them. -KB" />
          <ApplicationQuote quote="Known as one of the most abusive environments FactoryCat is the most durable floor cleaning machine brand on the market.  In a building where multiple operators use the same machine and steel objects test your operators ability, only a ruggedly reliable FactoryCat can stand up to the abuse. -JM" />
        </PageBody>
        <AutoImages />
        <Link to="/applications">
          <BackButton>All Applications</BackButton>
        </Link>
      </Layout>
    </>
  )
}

export default AutomotivePage
